/* General container for the map and filters */
.containerMapSpots {
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 1vh;
    border-radius: 15px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
    width: 75vw;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-bottom: 10vh;
    color: white;
}

/* Style for the map container */
.map-container {
    margin-top: 2vh;
    width: 100%;
    height: 65vh;
    margin-bottom: 2rem;
    border-width: 5px;
    border-color: black;
    border: #fff;
    border-radius: 20px;
}

/* Mapbox popup content */
.mapboxgl-popup-content {
    background: rgba(128, 0, 128, 0.5) !important;
    backdrop-filter: blur(10px) !important;
    -webkit-backdrop-filter: blur(10px) !important;
    border-radius: 10px !important;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
    color: white !important;
    border: 1px solid rgba(255, 255, 255, 0.3) !important;
    padding: 10px !important;
    font-size: 14px !important;
}

.mapboxgl-popup-content p {
    color: white !important;
    margin: 0 !important;
}

/* Sidebar styling */
.sidebar {
    background-color: rgba(35, 55, 75, 0.9);
    color: #fff;
    padding: 6px 12px;
    font-family: monospace;
    z-index: 1;
    position: absolute;
    top: 10px;
    left: 10px;
    margin: 0;
    border-radius: 3px;
}

.custom-marker {
    width: 30px;
    /* Adjust size as needed */
    height: 30px;
    /* Adjust size as needed */
    border-radius: 50%;
    background-color: rgba(194, 29, 29, 0.2);
    /* Semi-transparent white */
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(12, 1, 1, 0.85);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
    position: relative;
    /* Needed for absolute positioning of the star */
}

.marker-icon {
    width: 70%;
    /* Adjust size as needed */
    height: auto;
}

.level-star {
    position: absolute;
    top: 0px;
    right: 0px;

    border-radius: 50%;
    width: 13px;
    /* Adjust size as needed */
    height: 13px;
    /* Adjust size as needed */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    /* Adjust size as needed */
    color: white;
}

/* Filter popup container */
.filter-popup {
    position: absolute;
    top: 10%;
    right: 10%;
    background: rgba(108, 23, 136, 0.4);
    border-radius: 10px;
    padding: 1rem;
    box-shadow:
        0 4px 8px rgba(0, 0, 0, 0.2),
        inset 0 0 15px rgba(255, 255, 255, 0.1),
        0 0 10px rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    z-index: 1000;
    color: rgba(255, 255, 255, 0.85);
}

/* Checkbox list styling */
.checkbox-list {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    max-height: 200px;
    overflow-y: auto;
    margin-top: 10px;
    color: rgba(255, 255, 255, 0.85);
}

/* Label and input styling within the filter popup */
.filter-popup label {
    display: block;
    margin: 10px 0;
}

.filter-popup input[type="text"] {
    width: calc(100% - 20px);
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

/* Glassmorphic Button styling */
.filter-popup button,
.map-popup button {
    background: rgba(255, 255, 255, 0.15);
    color: rgba(255, 255, 255, 0.85);
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    margin-right: 10px;
    transition: background-color 0.3s, box-shadow 0.3s;
    display: block;
    width: 100%;
    margin-bottom: 10px;
    box-shadow:
        0 4px 8px rgba(0, 0, 0, 0.2),
        inset 0 0 15px rgba(255, 255, 255, 0.1),
        0 0 10px rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
}

.filter-popup button:hover,
.map-popup button:hover {
    background: rgba(255, 255, 255, 0.2);
    box-shadow:
        0 4px 8px rgba(0, 0, 0, 0.3),
        inset 0 0 20px rgba(255, 255, 255, 0.2),
        0 0 15px rgba(255, 255, 255, 0.5);
}

.filter-popup button.close,
.map-popup button.close {
    background: rgba(255, 0, 0, 0.4);
    color: rgba(255, 255, 255, 0.85);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.filter-popup button.close:hover,
.map-popup button.close:hover {
    background: rgba(255, 0, 0, 0.5);
    box-shadow:
        0 4px 8px rgba(0, 0, 0, 0.3),
        inset 0 0 20px rgba(255, 255, 255, 0.2),
        0 0 15px rgba(255, 255, 255, 0.5);
}

/* Map popup container */
.map-popup {
    position: absolute;
    background: rgba(108, 23, 136, 0.6);
    border-radius: 10px;
    padding: 1rem;
    box-shadow:
        0 4px 8px rgba(0, 0, 0, 0.2),
        inset 0 0 15px rgba(255, 255, 255, 0.1),
        0 0 10px rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    z-index: 1000;
    color: rgba(255, 255, 255, 0.85);
    max-width: 300px;
}

.map-popup-content {
    display: flex;
    flex-direction: column;
}

/* Filter icon styling */
.filter-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 1rem;
    cursor: pointer;
}

@media (max-width: 200px) {
    .map-container {
        height: 500px;
    }
}

/* Table styling for spots within 2km */
.tableMapSpot {
    width: 100%;
    max-width: 800px;
    padding: 5px;
    border-collapse: collapse;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.tableMapSpot th,
.tableMapSpot td {
    padding: 0.5rem;
    border: 1px solid #ddd;
    text-align: center;
    color: white;
}

/* Ensuring table headers have a distinct background */
.tableMapSpot th {
    background-color: rgba(108, 23, 136, 0.6);
}

.tableMapSpot button {
    background: rgba(255, 255, 255, 0.15);
    color: rgba(255, 255, 255, 0.85);
    border: none;
    border-radius: 10px;
    padding: 5px 10px;
    cursor: pointer;
    transition: background 0.3s, color 0.3s;
}

.tableMapSpot button:hover {
    background: rgba(255, 255, 255, 0.25);
    color: #FFD700;
}