/* Login.css */

/* General background style */
.backgroundRegister {
    height: 100vh;
    background-image: url('background.JPG');
    /* Adjust the path as needed */
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Container for the login elements */
.login-screen-container {
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    padding: 2rem;
    border-radius: 15px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
    width: 77vw;
    max-width: 90%;
    margin: auto;
    max-height: 80vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* The form itself */
.login-form {
    /* Lighter purple for the form */
    border: none;
    border-radius: 15px;
    padding: 2rem;
    color: #fff;
    width: 100%;
    /* Responsive width */
    max-width: 300px;
    /* Max width for the form */
    box-sizing: border-box;
    /* Ensures padding doesn't add to the width */
    display: flex;
    flex-direction: column;
}

/* Grouping label and input */
.input-group {
    margin-bottom: 1rem;
}

/* Style for the input fields */
input[type="email"],
input[type="password"] {
    width: 100%;
    padding: 0.75rem;
    margin-top: 0.5rem;
    border-radius: 15px;

    /* Seamless background */
    color: #000000;
    border: 1px solid rgb(255, 255, 255);
    /* Border like the game card */
    box-sizing: border-box;

}

/* Login button */
.login-button {
    padding: 0.75rem;
    margin-top: 1rem;
    border-radius: 15px;
    border: 1px solid rgb(255, 255, 255);
    background-color: #4e4cb8;
    background-image: linear-gradient(135deg,
            rgba(157, 80, 187, 0.8) 0%,
            rgba(110, 72, 170, 0.8) 100%);
    /* Vibrant color for the button */
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s;
    /* Smooth transition for hover */
}

/* Hover state for the login button */
.login-button:hover {
    background-color: #37358d;
    /* Darker shade for hover effect */
}

/* Text prompting user to register */
.register-prompt {
    margin-top: 1rem;
    text-align: center;
    color: rgba(255, 255, 255, 0.7);
}

/* Link to the registration page */
.register-link {
    color: rgba(255, 255, 255, 0.7);
    text-decoration: underline;
}

/* Hover state for the registration link */
.register-link:hover {
    color: #fff;
    /* Bright white for hover */
    text-decoration: none;
    /* Optional: remove underline on hover */
}

/* Ensures that the login form is centered */
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}