.point-card {
    background: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 2px solid transparent;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 0.5rem;
    margin: 1rem;
    color: white;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    transition: box-shadow 0.3s ease;
    height: auto;
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.point-card:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.points-control {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100px;
    margin-top: 10px;
}

.points-control button {
    background: rgba(255, 255, 255, 0.15);
    color: rgba(255, 255, 255, 0.85);
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.points-control button:hover {
    background-color: rgba(255, 255, 255, 0.3);
}

.points-control span {
    margin: 0 10px;
    font-size: 18px;
    color: white;
}