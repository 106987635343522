.containerCombatKeys {
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 1vh;
    border-radius: 15px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
    width: 75vw;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-bottom: 10vh;
    min-height: 75vh;

}

.combat-keys-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.combat-keys-table th,
.combat-keys-table td {
    border: 1px solid #ddd;
    padding: 8px;
}

.combat-keys-table th {
    background-color: #f2f2f2;
    text-align: left;
}

.copy-notification {
    color: green;
    margin-top: 10px;
}