.modal-overlay-troopSelection {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(108, 23, 136, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1050;
    /* Ensure this is higher than the map container's z-index */
}

.modal-content-troopSelection {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(108, 23, 136, 0.4);
    color: rgba(255, 255, 255, 0.85);
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    /* Responsive width */
    max-width: 500px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.8), 0 6px 12px rgba(0, 0, 0, 0.8), 0 9px 18px rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    overflow: auto;
    /* Allows scrolling within the modal if content is too long */
    z-index: 1050;
}

.modal-troop-icon-container {
    display: flex;
    align-items: center;
    padding: 10px;
    margin-bottom: 20px;
}

.modal-troop-icon {
    width: 50px;
    height: 50px;
    margin-right: 15px;
    border: 3px solid white;
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.modal-troop-name {
    color: white;
    font-size: 18px;
    font-weight: bold;
}


.close-button-troopSelection {
    background: red;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 16px;
    position: absolute;
    top: 10px;
    right: 10px;
}

.styled-button {
    background: rgba(255, 255, 255, 0.15);
    color: rgba(255, 255, 255, 0.85);
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.styled-button:hover {
    background: rgba(255, 255, 255, 0.3);
}

.attribute-level {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 5px;
    color: white;
}

.attribute-icon {
    width: 40px;
    /* Adjust the size of the icons as needed */
    height: 40px;
}




.stats-container {
    display: flex;
    flex-wrap: wrap;
    /* Allows stats to wrap onto the next line if not enough space */
    justify-content: space-between;
    gap: 10px;
    /* Adds a gap between each block for cleaner spacing */
}

.stats-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* Ensures content is spaced between */
    background: rgba(255, 255, 255, 0.1);
    /* Semi-transparent background */
    border-radius: 10px;
    padding: 10px;
    width: 24%;
    position: relative;
    /* Adjusted width for three items per row */

}

.stats-value {
    margin-left: auto;
    color: white;
    font-size: 16px;
    /* Slightly larger font size for readability */
    font-weight: bold;
    /* Makes the number bolder */
}



.unit-type-icon-defense {
    position: absolute;

    width: 30px;
    height: 30px;
    background: url('../zIcons/defense.png') no-repeat center center;
    background-size: cover;
    z-index: 1000;
}

.unit-type-image-defense {
    position: absolute;
    top: 7px;
    left: 9px;
    width: 12px;
    /* Menšia šírka než label */
    height: 12px;
    /* Menšia výška než label */
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.sword-icon .unit-type-image-defense {
    background-image: url('../zIcons/sword.png');
}

.bow-icon .unit-type-image-defense {
    background-image: url('../zIcons/bow.png');
}

.catapult-icon .unit-type-image-defense {
    background-image: url('../zIcons/catapult.png');
}




.unit-type-icon-attack {
    position: absolute;
    margin-left: 5px;
    width: 32px;
    height: 32px;
    background: url('../zIcons/attack.png') no-repeat center center;
    background-size: cover;
    z-index: 1000;
}

.unit-type-image-attack {
    position: absolute;
    top: 8px;
    left: 5px;
    width: 12px;
    /* Menšia šírka než label */
    height: 12px;
    /* Menšia výška než label */
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.sword-icon .unit-type-image-attack {
    background-image: url('../zIcons/sword.png');
}

.bow-icon .unit-type-image-attack {
    background-image: url('../zIcons/bow.png');
}

.catapult-icon .unit-type-image-attack {
    background-image: url('../zIcons/catapult.png');
}



/* Styling for the range slider */
.range-slider {
    -webkit-appearance: none;
    /* Override default CSS styles */
    appearance: none;
    width: 100%;
    /* Full-width */
    height: 8px;
    /* Specified height */
    background: #ddd;
    /* Grey background */
    outline: none;
    /* Remove outline */
    opacity: 0.7;
    /* Set transparency (cross-browser) */
    -webkit-transition: .2s;
    /* 0.2 seconds transition on hover */
    transition: opacity .2s;
}

.range-slider:hover {
    opacity: 1;
    /* Fully opaque on hover */
}

/* The slider handle (thumb) */
.range-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    /* Override default look */
    appearance: none;
    width: 18px;
    /* Set a specific slider handle width */
    height: 18px;
    /* Slider handle height */
    background: #4CAF50;
    /* Green background */
    cursor: pointer;
    /* Cursor on hover */
    border-radius: 50%;
    /* Circular handles */
    border: 2px solid #fff;
    /* White border */
}

.range-slider::-moz-range-thumb {
    width: 18px;
    /* Set a specific slider handle width */
    height: 18px;
    /* Slider handle height */
    background: #4CAF50;
    /* Green background */
    cursor: pointer;
    /* Cursor on hover */
    border-radius: 50%;
    /* Circular handles */
    border: 2px solid #fff;
    /* White border */
}