.youtube-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    /* 16:9 aspect ratio */
    margin-bottom: 1rem;
}

.youtube-player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.beep-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    padding: 10px 20px;
    font-size: 16px;
    background-color: red;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.question-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    text-align: center;
}

.question-overlay button {
    display: block;
    margin: 10px 0;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
}

p {
    color: white;
    text-align: center;
}

@media (orientation: portrait) {
    .landscape-warning {
        display: block;
        text-align: center;
        padding: 10px;
        background-color: #f8d7da;
        color: #721c24;
    }
}

@media (orientation: landscape) {
    .landscape-warning {
        display: none;
    }
}