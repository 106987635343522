body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.container-screen {
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
  width: 75vw;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-bottom: 10vh;
  height: 70vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

/* Štýlovanie scrollbaru pre celý dokument alebo konkrétne elementy */
::-webkit-scrollbar {
  width: 5px;
  /* šírka scrollbaru pre vertikálny scroll */
  height: 5px;
  /* výška scrollbaru pre horizontálny scroll */
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
  /* farba pozadia dráhy scrollbaru */
}

::-webkit-scrollbar-thumb {
  background-color: rgba(169, 169, 169, 0.4);
  /* farba pohyblivej časti scrollbaru */
  border-radius: 10px;
  /* zaoblenie rohov scrollbaru */
  border: 1px solid transparent;
  /* rámik okolo pohyblivej časti scrollbaru */
}


@media (orientation: portrait) {
  .container-screen {
    height: 70vh;
  }
}

/* Landscape orientation */
@media (orientation: landscape) {
  .container-screen {
    height: 70vh;
  }
}