/* Assuming this is included in ResearchCard.css or a separate ResearchCardToLearn.css */
.research-card-learning {
    background-color: rgba(255, 255, 255, 0.3);
    border: 2px solid transparent;

    padding: 1rem;
    border-radius: 15px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);

    color: white;

    cursor: pointer;
    transition: transform 0.2s;
    overflow: visible;

    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
}








.research-card-learning:hover {
    transform: scale(1.05);
}

.progress-bar-container {
    position: relative;
    /* Set relative positioning for positioning the label absolutely inside it */
    width: 100%;
    background-color: #ccc;
    border-radius: 8px;
    overflow: hidden;
    margin-top: 10px;
}

.progress-bar {
    height: 10px;
    background-color: #4CAF50;
    width: 50%;
    /* Dynamic width based on progress */
}

.progress-label {
    position: absolute;
    /* Absolute position for the label */
    top: 50%;
    /* Center vertically */
    left: 50%;
    /* Center horizontally */
    transform: translate(-50%, -50%);
    /* Adjust the positioning to be truly centered */
    font-weight: bold;
    /* Optional: makes the label bold */
}