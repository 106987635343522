/* SpotActivitiesTable.css */
.spot-activities-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.spot-activities-table th,
.spot-activities-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.spot-activities-table th {
    background-color: #f4f4f4;
    color: #333;
}

.spot-activities-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.spot-activities-table tr:hover {
    background-color: #f1f1f1;
}

.spot-activities-table button {
    padding: 6px 10px;
    color: white;
    background-color: #007BFF;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.spot-activities-table button:hover {
    background-color: #0056b3;
}