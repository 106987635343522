/* researchCard.css */

.verification-cards-container {
    display: flex;
    flex-wrap: wrap;
    /* Umožní, aby sa položky zalomili na nový riadok */
    justify-content: space-evenly;
    /* Zarovná položky na stred */
    gap: 20px;
    /* Vytvorí medzeru medzi kartami */
    padding: 20px;
    /* Pridá vnútorný odstup okolo obsahu kontajnera */
}

.verification-card {
    background-color: rgba(255, 255, 255, 0.1);

    padding: 1rem;
    border-radius: 15px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
    margin: 1rem 0;
    color: white;
    width: 280px;
    z-index: 50 !important;
}

.verification-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
    font-family: 'Roboto', sans-serif;
    font-size: 1.8rem;
    color: #ffffff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.verification-card-name {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
}

.verification-card-icons {
    display: flex;
    font-size: small;
    gap: 0.5rem;
}

.verification-card-description {
    margin-bottom: 1rem;
    font-size: 1rem;
}

.verification-card-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.verification-card-improvements {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.verification-card-improvement {
    display: flex;
    align-items: center;
    gap: 0.25rem;
}

.verification-card-value {
    font-size: 1rem;
}

.verification-card-level {
    font-size: 1.5rem;
    font-weight: bold;
}

/* Icon styling */
.verification-card-icon-container {
    display: flex;
    justify-content: center;

}