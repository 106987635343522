/* Base styling for all troop cards */
.troop-card {
    background: linear-gradient(145deg, #4b3768, #302245);
    color: #fff;
    border-radius: 10px;
    overflow: visible;
    margin: 10px;
    box-shadow: 0 10px 32px rgba(0, 0, 0, 0.3);

    transition: transform 0.2s;
    position: relative;
    /* min-width: calc(28% - 10px);
    max-width: calc(28% - 10px);
    /* Added for absolute positioning of the level */
}

.troop-card:hover {
    transform: translateY(-5px);
}

/* Header within the card */
.troop-header {
    display: flex;
    justify-content: space-between;
    border-radius: 10px 10px 0 0;
    padding: 10px;
    background: rgba(0, 0, 0, 0.2);
    /* semi-transparent overlay */
}

.level-icon {
    width: 30px;
    /* Alebo iný vhodný rozmer */
    height: 30px;
    /* Udržiavať proporcionálny rozmer */
    position: absolute;
    top: -9px;
    /* Nastav podľa potreby */
    right: -7px;
    /* Nastav podľa potreby */
    display: flex;
    align-items: center;
    /* Zarovná obsah (číslo) vertikálne na stred */
    justify-content: center;
    /* Zarovná obsah (číslo) horizontálne na stred */
    z-index: 100;

    color: white;
    font-weight: bold;
    font-size: 14px;
    /* Farba textu */
    background: url('../zIcons/label.png') no-repeat center center;
    background-size: cover;
    /* Alebo 'contain' podľa potreby */
}


.troop-name {
    flex-grow: 1;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
}

/* Icon styling */
.troop-icon-container {
    display: flex;
    justify-content: center;
    padding: 10px;
    padding-bottom: 0;
}

.troop-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 4px solid #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
}

.unit-type-icon {
    position: absolute;
    top: -9px;
    left: 7px;
    transform: translateX(-50%);
    width: 30px;
    height: 30px;
    background: url('../zIcons/label.png') no-repeat center center;
    background-size: cover;
    z-index: 1000;
}

.unit-type-image {
    position: absolute;
    top: 8px;
    left: 9px;
    width: 12px;
    /* Menšia šírka než label */
    height: 12px;
    /* Menšia výška než label */
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.sword-icon .unit-type-image {
    background-image: url('../zIcons/sword.png');
}

.bow-icon .unit-type-image {
    background-image: url('../zIcons/bow.png');
}

.catapult-icon .unit-type-image {
    background-image: url('../zIcons/catapult.png');
}

.troop-count {
    text-align: center;
    background: rgba(255, 255, 255, 0.15);
    padding: 5px 10px;
    border-radius: 0 0 10px 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    color: #ffffff;
    font-size: 16px;
    font-weight: bold;
    margin-top: 10px;
    /* Add margin to position below the icon */
    /* Adjust the width as needed */
    align-self: center;
    /* Center it under the icon */
}

.troop-card.basic {
    background: linear-gradient(145deg, #134E5E, #71B280);
}

/*
.troop-card.basic {
    background: linear-gradient(145deg, #134E5E, #71B280);
}*/
.troop-card.rare {
    background: linear-gradient(145deg, #000046, #1CB5E0);
}


.troop-card.epic {
    background: linear-gradient(145deg, #ff00cc, #333399);
}



.troop-card.legendary {
    background: linear-gradient(145deg, #f12711, #f5af19);
}



.troop-card.cosmic {
    background: linear-gradient(145deg,
            rgba(255, 0, 0, 1),
            rgba(255, 0, 0, 1),
            rgba(255, 0, 0, 1),
            /* červená */
            rgba(255, 154, 0, 1),
            /* oranžová */
            rgba(208, 222, 33, 1),
            /* žltá */
            rgba(79, 220, 74, 1),
            /* zelená */
            rgba(63, 218, 216, 1),
            /* svetlo modrá */
            rgba(47, 201, 226, 1),
            /* modrá */
            rgba(28, 127, 238, 1),
            /* tmavo modrá */
            rgba(95, 21, 242, 1),
            rgba(95, 21, 242, 1),
            rgba(95, 21, 242, 1));
    /* červená opäť na konci */
    background-size: 200% 200%;
    /* Zmena veľkosti pozadia */
    animation: GradientShift 3s ease infinite;
}

@keyframes GradientShift {
    0% {
        background-position: 100% 0%;
        /* Štartovacia pozícia vpravo hore */
    }

    50% {
        background-position: 0% 100%;
        /* Pohyb k ľavému dolnému rohu */
    }

    100% {
        background-position: 100% 0%;
        /* Späť na štartovaciu pozíciu */
    }
}