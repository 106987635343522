/* SpellToLearn.css */
.researchs-to-learn-container {
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    padding: 2rem;
    border-radius: 15px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
    width: 75vw;
    margin: auto;
    color: white;
    margin-bottom: 10vh;
    min-height: 75vh;


}