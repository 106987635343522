.top-panel {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 6vh;
    background: linear-gradient(135deg, #9D50BB 0%, #6E48AA 100%);
    color: white;
    padding: 0.5vh 0;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    font-family: 'Ubuntu Condensed', sans-serif;
    z-index: 1000;
    position: fixed;
    width: 100%;
    top: 0;
}

.icon-container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
}

.fa-iconT {
    font-size: 5vh;
    margin: 0 1rem;
    cursor: pointer;
}

.fa-iconTactive {
    font-size: 5vh;
    margin: 0 1rem;
    cursor: pointer;
    color: yellow;
}