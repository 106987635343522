.verdict-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    background: rgba(108, 23, 136, 0.4);

}

.verdict-modal-content {
    background: rgba(108, 23, 136, 0.7);
    color: rgba(255, 255, 255, 0.85);
    padding: 20px;
    border-radius: 10px;
    max-width: 500px;
    width: 100%;
    max-height: 70vh;
    /* Ensure it doesn't overflow the viewport */
    overflow-y: auto;
    /* Allow scrolling if the content overflows */
    text-align: left;
    box-shadow:
        0 9px 18px rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    margin-top: auto;
    margin-bottom: auto;
}

.verdict-modal-content h3 {
    margin-bottom: 20px;
    font-size: 1.5rem;
}

.verdict-name,
.verdict-description {
    margin: 0;
    font-size: 1rem;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    font-size: 0.9rem;
}

.video {
    margin-top: 10px;
}

.styled-input {
    width: calc(100% - 20px);
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    background: rgba(255, 255, 255, 0.15);
    color: rgba(255, 255, 255, 0.85);
}

.styled-input::placeholder {
    color: rgba(255, 255, 255, 0.7);
}

.styled-button {
    background: rgba(255, 255, 255, 0.15);
    color: rgba(255, 255, 255, 0.85);
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    transition: background 0.3s, color 0.3s;
    margin-top: 10px;
    width: calc(100% - 20px);
}

.styled-button:hover {
    background: rgba(255, 255, 255, 0.25);
    color: #FFD700;
}

.close-button {
    margin-top: 20px;
}