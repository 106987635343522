.video-section {
    position: relative;
    /* Umožňuje absolútne pozicionovanie videa v rámci tejto sekcie */
    width: 100%;
    /* Šírka videa bude rovná šírke kontajnera */
    height: 0;
    /* Nastaví výšku na nulu, čím sa uvoľní miesto pre padding-top */
    padding-top: 56.25%;
    /* Percentuálny pomér výšky k šírke (16:9 aspect ratio) */
    overflow: hidden;
    /* Skryje časti videa, ktoré by mohli presahovať kontajner */
    margin-top: 20px;
}

video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.details-container {
    text-align: left;
    margin-bottom: 20px;
}

.detail-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* Vertically aligns items in the flex container */
    width: 100%;
}

.detail-verification-header {
    display: flex;
    align-items: center;
    justify-content: space-around;
    /* Vertically aligns items in the flex container */
    width: 100%;
}

.detail-item-description {
    margin-bottom: 25px;
    text-align: center;
}

.detail-item-note {
    font-style: italic;
    text-align: center;
    /* Nastaví text na kurzívu */
}

.detail-item-url {
    text-align: center;
    /* Zarovnáva text na stred */
}

.detail-item-url a {
    text-decoration: underline;
    /* Podčiarkuje text */
    color: #eaee00a8;
    /* Nastavuje typickú modrú farbu pre odkazy */
    cursor: pointer;
    /* Zobrazuje ukazovateľ myši ako ruku, čo naznačuje klikateľný odkaz */
}

.detail-title {
    text-align: center;

}

.detail-dateTime {
    text-align: right;

}