/* researchCard.css */

.research-cards-container {
    display: flex;
    flex-wrap: wrap;
    /* Umožní, aby sa položky zalomili na nový riadok */
    justify-content: space-evenly;
    /* Zarovná položky na stred */
    gap: 20px;
    /* Vytvorí medzeru medzi kartami */
    padding: 20px;
    /* Pridá vnútorný odstup okolo obsahu kontajnera */
}

.research-card {
    background-color: rgba(255, 255, 255, 0.1);

    padding: 1rem;
    border-radius: 15px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
    margin: 1rem 0;
    color: white;
    width: 280px;
    z-index: 50 !important;
}

.research-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
    font-family: 'Roboto', sans-serif;
    font-size: 1.8rem;
    color: #ffffff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.research-name {
    font-size: 1.5rem;
    font-weight: bold;
}

.research-icons {
    display: flex;
    font-size: medium;
    gap: 0.5rem;
}

.research-description {
    margin-bottom: 1rem;
    font-size: 1rem;
}

.research-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.research-improvements {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.research-improvement {
    display: flex;
    align-items: center;
    gap: 0.25rem;
}

.improvement-value {
    font-size: 1rem;
}

.research-level {
    font-size: 1.5rem;
    font-weight: bold;
}

.border-color-red {
    border: 2px solid red;
}

.border-color-green {
    border: 2px solid green;
}

.border-color-blue {
    border: 2px solid blue;
}

.border-color-yellow {
    border: 2px solid yellow;
}

.border-color-purple {
    border: 2px solid purple;
}

.border-color-orange {
    border: 2px solid orange;
}

.border-color-default {
    border: 2px solid gray;
}

/* @media (max-width: 768px) {
    .spell-card {
        box-sizing: border-box;
        width: calc(100% - 2rem);
        margin: 1rem;
    }
}

@media (min-width: 769px) {
    .spell-card {
        width: 90%;
        padding: 1rem;
        margin: 1rem;
    }
} */

/* Icon styling */
.research-icon-container {
    display: flex;
    justify-content: center;

}

.research-troop-count {
    width: 30px;
    height: 30px;
    border-radius: 35%;
    color: white;
    display: flex;
    padding: 5px;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    margin-bottom: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5), 0 10px 20px rgba(0, 0, 0, 0.2);
    /* Pridanie tieňa pre 3D efekt */
}

.research-troop-count.basic {
    background: linear-gradient(145deg, #134E5E, #71B280);
}

.research-troop-count.rare {
    background: linear-gradient(145deg, #000046, #1CB5E0);
}

.research-troop-count.epic {
    background: linear-gradient(145deg, #ff00cc, #333399);
}

.research-troop-count.legendary {
    background: linear-gradient(145deg, #f12711, #f5af19);
}


.research-troop-count.cosmic {
    background: linear-gradient(145deg,
            rgba(255, 0, 0, 1),
            rgba(255, 0, 0, 1),
            rgba(255, 0, 0, 1),
            /* červená */
            rgba(255, 154, 0, 1),
            /* oranžová */
            rgba(208, 222, 33, 1),
            /* žltá */
            rgba(79, 220, 74, 1),
            /* zelená */
            rgba(63, 218, 216, 1),
            /* svetlo modrá */
            rgba(47, 201, 226, 1),
            /* modrá */
            rgba(28, 127, 238, 1),
            /* tmavo modrá */

            rgba(95, 21, 242, 1));
    /* červená opäť na konci */
    background-size: 200% 200%;
    /* Zmena veľkosti pozadia */
    animation: GradientShift 2s ease infinite;
}

@keyframes GradientShift {
    0% {
        background-position: 100% 0%;
        /* Štartovacia pozícia vpravo hore */
    }

    50% {
        background-position: 0% 100%;
        /* Pohyb k ľavému dolnému rohu */
    }

    100% {
        background-position: 100% 0%;
        /* Späť na štartovaciu pozíciu */
    }
}