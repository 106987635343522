/* CharacterAttributeLevel.css */

.containerCharacterItemCraft {
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 1vh;
    border-radius: 15px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
    width: 75vw;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-bottom: 10vh;
    min-height: 75vh;

}