    .videoName {
        font-size: 15px;
        /* nastaví veľkosť písma */
        line-height: 1.2;
        /* nastaví výšku riadka */
        margin-bottom: 3px;
        /* nastaví okraj na spodnej strane pre oddelenie videí */
        padding: 5px 0;
        /* pridá vnútorné odsadenie na hornej a dolnej strane */
        color: #ffffff;
        /* farba textu */
        font-weight: 600;
        /* hrúbka písma */
    }

    .strikethrough {
        text-decoration: line-through;
        color: #ccc;
        /* Sivá farba na znázornenie že je video dokončené */
    }