/* CharacterSpell.css */
.containerCharacterSpell {
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    padding: 2rem;
    border-radius: 15px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
    width: 77vw;
    max-width: 90%;
    margin: auto;
    min-height: 75vh;

    align-items: center;
}

h1 {
    color: #fff;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}

.table-container {
    width: 100%;
    overflow-x: auto;
    /* Ensures the table is scrollable horizontally */
}

.table {
    width: 100%;
    margin-bottom: 1rem;
    color: white;
    border-collapse: collapse;
    font-size: 2vw;
}

.table th,
.table td {
    padding: 0.5rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}

.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
}

.table tbody+tbody {
    border-top: 2px solid #dee2e6;
}

.table-bordered {
    border: 1px solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6;
}

.table-bordered thead th,
.table-bordered thead td {
    border-bottom-width: 2px;
}





/* Responsive design for mobile devices */
@media (max-width: 768px) {
    .containerCharacterSpell {

        padding: 1rem;
    }

    .table {
        font-size: 4vw;
    }


}