.combat-selection-screen {
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    border-radius: 15px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
    width: 90vw;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    position: relative;
    margin-bottom: 10vh;
    max-height: 79vh;
    min-height: 79vh;
    overflow-y: scroll;


}

.troop-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    /* Aligns items to the start of the container */
    gap: 10px;
    /* Adds space between items */
    padding: 10px;
}