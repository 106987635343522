.key-icon-number-container {
    display: flex;
    /* Zarovnáva ikony vedľa seba */
    align-items: center;
    /* Vertikálne zarovnanie */
    justify-content: center;
    /* Horizontálne zarovnanie na stred */
    gap: 10px;
    /* Odstup medzi ikonami */
}

.key-icon {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 20px;
    color: white;
    background: url('../zIcons/label.png') no-repeat center center;
    background-size: cover;
}