/* CardMenuButton.css */
.card-menu-button {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 15px;
    padding: 0.6rem;
    margin: 0.5rem;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
    text-align: center;
    cursor: pointer;
    transition: transform 0.3s, box-shadow 0.3s;
    flex: 1;
    /* Ensure buttons are responsive and fit in a row */
    max-width: 30%;
    /* Limit the width for better visual appearance */
    max-height: 1vh;
    color: white;

    display: flex;
    justify-content: center;
    /* Horizontally center content inside the button */
    align-items: center;
    /* Vertically center content inside the button */
    width: 100%;
    /* Take full width of the parent container to ensure margin/padding is applied correctly */

}

.card-menu-button:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
}

.card-menu-button h2 {
    color: rgba(255, 255, 255, 0.8);
    margin: 0;
    max-height: 1vh;
    font-size: 0.5rem;
}