.custom-marker {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    border: 2px solid grey;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
    transition: transform 0.3s ease-in-out;
}

.custom-marker:hover {
    transform: scale(1.1);
}

.level-indicator {
    width: 25px;
    /* Upravte podľa veľkosti vašej ikony */
    height: 25px;
    /* Upravte podľa veľkosti vašej ikony */
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    font-size: 12px;
    /* Upravte podľa potreby */
    color: white;
    /* Farba textu */
    background: url('../zIcons/label.png');
    background-size: cover;
    /* Zabezpečuje, že obrázok pokryje celý kruh */
}

/* Prispôsobte pozíciu podľa potreby */
.level-indicator:nth-child(1) {
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%) translateY(5px);
}

.level-indicator:nth-child(2) {
    top: 10%;
    left: -10%;
    transform: translate(-50%, -50%) translateY(5px);
}

.level-indicator:nth-child(3) {
    top: 10%;
    left: 110%;
    transform: translate(-50%, -50%) translateY(5px);
}

.level-indicator-enemy {
    width: 15px;
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    font-size: 10px;
    color: white;
    background-color: grey;
    /* Soft dark grey for a modern look */
    border: none;
    /* Removing the border for a cleaner look */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
    /* Subtle shadow for depth */
    transform: translate(-50%, -50%) rotate(45deg);
    top: -27%;
    left: 50%;
}

.level-indicator-enemy span {
    display: block;
    transform: rotate(-45deg);
}