/* CharacterSpells.css */
.containerCharacterItems {
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    padding: 2rem;
    border-radius: 15px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);

    max-width: 90%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
    min-height: 75vh;


}




h1,
h2 {
    color: #fff;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}

.table-container {

    overflow-x: auto;
    /* Ensures the table is scrollable horizontally */
}

.table {

    margin-bottom: 1rem;
    color: white;
    border-collapse: collapse;
    font-size: 2vw;
}

.table th,
.table td {
    padding: 0.5rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}

.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
}

.table tbody+tbody {
    border-top: 2px solid #dee2e6;
}

.table-bordered {
    border: 1px solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6;
}

.table-bordered thead th,
.table-bordered thead td {
    border-bottom-width: 2px;
}

.buttonA {
    background-color: #4CAF50;
    border: none;
    color: white;

    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 2vw;
    width: 2vw;
    height: 2vh;

    cursor: pointer;
    border-radius: 8px;
    transition-duration: 0.4s;
}

.buttonA:hover {
    background-color: white;
    color: black;
    border: 2px solid #4CAF50;
}

/* Responsive design for mobile devices */
@media (max-width: 768px) {

    .table {

        font-size: 3vw;
    }

    .buttonA {
        padding: 8px 16px;

        height: 2vh;
    }
}