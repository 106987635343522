.generic-card {
    background: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    /* For Safari */
    border: 2px solid transparent;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 0.5rem;
    margin: 1rem;
    color: white;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    transition: box-shadow 0.3s ease;
    height: auto;
    min-height: 50px;
    /* Consider increasing height if necessary */
    display: flex;
    justify-content: center;
    /* Centers content horizontally */
    align-items: center;
    /* Centers content vertically */
}

.generic-card:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.question-container {
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    padding: 2rem;
    border-radius: 15px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
    width: 75vw;
    margin: auto;
    color: white;
    margin-bottom: 10vh;
    min-height: 75vh;
}

.selected-container {
    border-bottom: 2px dashed rgba(255, 255, 255, 0.3);
    /* Distinct visual separator */
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    /* Space between containers */
}



/* You might want to ensure visibility for text and other elements */
.generic-card p {
    color: white;
    /* Ensures text is visible against the backdrop */
}

.navigation-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    margin-top: 20px;
    position: relative;
}

.arrow {
    background: rgba(255, 255, 255, 0.15);
    color: rgba(255, 255, 255, 0.85);
    border: none;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    font-size: 24px;
    transition: background-color 0.3s ease;
}

.arrow:hover {
    background: rgba(255, 255, 255, 0.3);
}

.progress-container {
    flex-grow: 1;
    margin: 0 10px;
    position: relative;
    height: 8px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    overflow: hidden;
}

.progress-bar {
    height: 100%;
    background: rgba(255, 255, 255, 0.85);
    transition: width 0.3s ease;
}

.progress-indicator {
    position: absolute;
    left: 50%;
    top: -25px;
    transform: translateX(-50%);
    font-size: 16px;
    color: rgba(255, 255, 255, 0.85);
}

.send-answer-button {
    background: rgba(255, 255, 255, 0.15);
    color: rgba(255, 255, 255, 0.85);
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.send-answer-button:hover {
    background: rgba(255, 255, 255, 0.3);
}