body,
html {
    height: 100vh;
    margin: 0;
    padding: 0;
}

.container {
    height: 100vh;
}

.content {
    padding: 1rem;
    backdrop-filter: blur(10px);
    background-image: url('background.JPG');
    background-size: cover;
    height: 86vh;
    padding-top: 10vh;
}

.bottom-navbar {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 6vh;
    background: linear-gradient(135deg, #9D50BB 0%, #6E48AA 100%);
    color: white;
    padding: 0.5vh 0;
    box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.3);
    font-family: 'Ubuntu Condensed', sans-serif;
    z-index: 1000;
    position: fixed;
    width: 100%;
    bottom: 0;
}

.bottom-navbar button {
    background: none;
    border: none;
    color: rgba(255, 255, 255, 0.85);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 0.3s ease;
}

.bottom-navbar .active {
    color: #FFD700;
}

.fa-iconB {
    font-size: 5vh;
}

/* Top panel styling */
.top-panel {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 6vh;
    background: linear-gradient(135deg, #9D50BB 0%, #6E48AA 100%);
    color: white;
    padding: 0.5vh 0;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    font-family: 'Ubuntu Condensed', sans-serif;
    z-index: 1000;
    position: fixed;
    width: 100%;
    top: 0;
}

.top-panel button {
    background: none;
    border: none;
    color: rgba(255, 255, 255, 0.85);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 0.3s ease;
}

.top-panel .active {
    color: #FFD700;
}

.fa-iconT {
    font-size: 5vh;
}