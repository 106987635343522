.item-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 15px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
    margin: 1rem auto;
    /* Center the card and add margin around */
    color: white;
    width: 90%;
    /* Adjust width to not exceed screen size */
    max-width: 500px;
    /* Add a max-width for larger screens */
    border: 2px solid #ccc;
    /* Dynamically set based on item attribute */
}

.item-name {
    font-size: 1.5rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 10px;
    /* Space between text and icon */
}

.item-icon {
    width: 20px;
    /* Smaller width */
    height: auto;
    filter: brightness(0) invert(1);
    /* Make the icon white */
}

.item-description,
.item-footer {
    width: 100%;
    text-align: center;
    padding: 0 10px;
}