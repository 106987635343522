.combat-interaction {
    padding: 20px;
    /* Dark purple background */
    border-radius: 5px;
    margin-top: 20px;
    color: #ffffff;
    /* White text color */
}

.interactions-list {
    margin-top: 10px;
}

.interaction {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.1);
    /* Slightly visible background for each interaction */
    border: 1px solid rgba(255, 255, 255, 0.2);
    /* Subtle white border */
    border-radius: 5px;
}

.troop-icon {
    display: inline-block;
    width: 50px;
    height: 50px;
    background-size: cover;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    border: 5px solid #ffffff;
    /* White border for better visibility */
    position: relative;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    /* Subtle shadow for depth */
}

.troop-icon.attacker {
    background-color: #4CAF50;
    /* Green background for attackers */
    box-shadow: 0 0 0 5px #4CAF50;
    /* Larger shadow to create a glowing border effect */
}

.troop-icon.defender {
    background-color: #F44336;
    /* Red background for defenders */
    box-shadow: 0 0 0 5px #F44336;
    /* Larger shadow to create a glowing border effect */
}

.action-direction {
    margin: 0 20px;
    font-size: 24px;
    /* Adjust size as needed */
}

.combat-action {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}

.casualty-report p {
    margin: 5px 0;
    font-size: 14px;
    color: #ffffff;
    /* Ensure text is white for readability */
}

.unit-type-icon-interaction {
    width: 120px;
    height: 120px;
    background: url('../zIcons/label.png') no-repeat center center;
    background-size: cover;
    z-index: 1000;
}

.unit-type-image-interaction {
    position: relative;
    top: 30px;
    left: 30px;
    width: 55px;
    /* Menšia šírka než label */
    height: 55px;
    /* Menšia výška než label */
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.sword-icon .unit-type-image-interaction {
    background-image: url('../zIcons/sword.png');
}

.bow-icon .unit-type-image-interaction {
    background-image: url('../zIcons/bow.png');
}

.catapult-icon .unit-type-image-interaction {
    background-image: url('../zIcons/catapult.png');
}