/* AttributeCard.css */

.attribute-card {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    width: 100%;
    padding: 1rem;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    color: #fff;
    position: relative;
    /* Needed for absolute positioning inside */
}

.attribute-level {
    width: 50px;
    height: 50px;
    border-radius: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    margin-right: 10px;
    /* Space between the level and the name/progress bar */
    flex-shrink: 0;
    /* Prevents the circle from shrinking */
    position: relative;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    /* Allows absolute positioning of children */
}

.level-container {
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(5px);
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    margin-left: auto;
    /* Moves the level to the right */
    font-size: 1.5rem;
    color: #000;
    /* Text color */
}



.attribute-icon {
    position: absolute;
    width: 75%;
    /* Adjust based on your preference */
    height: 75%;
    /* Adjust based on your preference */
    top: 12%;
    /* Center the icon */
    left: 12%;
    /* Center the icon */
    object-fit: contain;
    /* Keeps the aspect ratio of the icon */
}

.attribute-details {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-right: 10px;
    /* Ensure space between details and level */
}

.attribute-name {
    font-size: 1.2rem;
    margin-bottom: 5px;
    /* Space between the name and the progress bar */
    text-align: left;
    /* Align text to the left */
}

.attribute-progress {
    width: 100%;
    /* Full width of the remaining space */
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    height: 1rem;
}

.attribute-progress-bar {
    height: 100%;
    background-color: rgba(135, 4, 161, 0.4);
    /* Bright purple for visibility */
    position: absolute;
    top: 0;
    left: 0;
}

.attribute-progress-text {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 0;
    /* Align vertically at the top */
    bottom: 0;
    /* Align vertically at the bottom */
    display: flex;
    align-items: center;
    /* Center vertically */
    justify-content: center;
    /* Center horizontally */
    font-size: 0.8rem;
    color: white;
    /* Biela farba písma */
    text-shadow: 1px 1px 2px black;
    /* Čierny tieň pre lepšiu čitateľnosť */
    font-weight: bold;
    /* Tučné písmo pre zvýraznenie */
    /* Ensure the text is visible */
}