.modal-overlay-spotGenKey {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(108, 23, 136, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1050;
    /* Ensure this is higher than the map container's z-index */
}

.modal-content-spotGenKey {
    background: rgba(108, 23, 136, 0.7);
    color: rgba(255, 255, 255, 0.85);
    padding: 20px;
    border-radius: 10px;
    max-width: 500px;
    width: 90%;
    /* Responsive width */
    margin: auto;
    /* Centers in the flex container */
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.8), 0 6px 12px rgba(0, 0, 0, 0.8), 0 9px 18px rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    position: relative;
    overflow: auto;
    /* Allows scrolling within the modal if content is too long */
}

.confirm-button {
    background: green;
    color: white;
}

.close-button {
    background: red;
    color: white;
}

/* Reuse existing button styles */
.styled-button {
    background: rgba(255, 255, 255, 0.15);
    color: rgba(255, 255, 255, 0.85);
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.styled-button:hover {
    background: rgba(255, 255, 255, 0.3);
}

.reward-container {
    display: flex;
    flex-direction: row;
    /* Položky sa usporiadajú vedľa seba */
    flex-wrap: wrap;
    /* Umožní položkám začať nový riadok, keď nie je dostatok miesta */
    align-items: center;
    justify-content: center;
    /* Zarovnanie položiek do stredu vo vertikálnom smere */
    gap: 10px;
    /* Medzera medzi položkami */
    margin-top: 15px;
}

.attribute-level {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 5px;
    color: white;
}

.attribute-icon {
    width: 40px;
    /* Adjust the size of the icons as needed */
    height: 40px;
}