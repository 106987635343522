.verdict-slider {
    width: 100%;
    margin: 20px 0;
    position: relative;
}

.slider-container {
    position: relative;
    width: 100%;
}

.slider {
    width: 100%;
    margin-bottom: 10px;
    appearance: none;
    background: rgba(255, 255, 255, 0.7);
    height: 5px;
    border-radius: 5px;
    outline: none;
    position: relative;
    z-index: 2;
}

.slider::-webkit-slider-thumb {
    appearance: none;
    width: 50px;
    /* Adjusted width to accommodate emoji */
    height: 50px;
    /* Adjusted height to accommodate emoji */
    background: transparent;
    cursor: pointer;
    position: relative;
    z-index: 3;
}

.slider::-moz-range-thumb {
    width: 50px;
    /* Adjusted width to accommodate emoji */
    height: 50px;
    /* Adjusted height to accommodate emoji */
    background: transparent;
    cursor: pointer;
    position: relative;
    z-index: 3;
}

.emoji-slider-thumb {
    position: absolute;
    top: -10px;
    /* Adjusted to center the emoji above the slider */
    font-size: 2rem;
    /* Adjusted font size for emoji */
    transform: translateX(-50%);
    pointer-events: none;
    /* Prevent interfering with the slider */
    z-index: 5;
}

.slider-label {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    font-size: 1rem;
}