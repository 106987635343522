.research-verification-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.33);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
}

.research-verification-modal-content {
    background: rgba(108, 23, 136, 0.7);
    color: rgba(255, 255, 255, 0.85);
    padding: 20px;
    border-radius: 10px;
    max-width: 500px;
    width: 100%;
    text-align: left;
    box-shadow:
        0 9px 18px rgba(0, 0, 0, 0.33);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.3);

    scrollbar-width: thin;
    /* "auto" or "thin" */
    scrollbar-color: rgba(255, 255, 255, 0.5) rgba(108, 23, 136, 0.2);
    z-index: 2001;
}

.verification-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.activity-info {
    display: flex;
    flex-direction: column;
}

.activity-details {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.icon-circle {
    width: 30px;
    height: 30px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

.icon-circle img {
    width: 20px;
    height: 20px;
}

.activity-info h4 {
    margin: 0;
    font-size: 1rem;
}

.spot-name h3 {
    margin: 0;
    font-size: 1.2rem;
    text-align: right;
}

.modal-body {
    margin-top: 10px;
}

.description {
    font-size: 0.9rem;
    margin: 0;
}

.level-note {
    font-size: 0.9rem;
    margin-top: 5px;
}

.styled-input {
    width: calc(100% - 20px);
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    background: rgba(255, 255, 255, 0.15);
    color: rgba(255, 255, 255, 0.85);
}

.styled-input::placeholder {
    color: rgba(255, 255, 255, 0.7);
    /* Placeholder color */
}

.styled-button {
    background: rgba(255, 255, 255, 0.15);
    color: rgba(255, 255, 255, 0.85);
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 1.5vh;
    cursor: pointer;
    transition: background 0.3s, color 0.3s;
    margin-top: 10px;
    width: calc(100% - 20px);
}

.styled-button:hover {
    background: rgba(255, 255, 255, 0.25);
    color: #FFD700;
}

.close-button {
    margin-top: 20px;
}

.upload-message {
    margin-top: 10px;
    font-size: 0.9rem;
    color: rgba(255, 255, 255, 0.85);
}

/* Custom scrollbar for WebKit browsers */
.verification-modal-content::-webkit-scrollbar {
    width: 8px;
    /* width of the entire scrollbar */
}

.verification-modal-content::-webkit-scrollbar-track {
    background-color: rgba(108, 23, 136, 0.2);
    /* color of the track (part the scrollbar can move within) */
}

.verification-modal-content::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.5);
    /* color of the scroll thumb */
    border-radius: 4px;
    /* roundness of the scroll thumb */
    border: 2px solid rgba(108, 23, 136, 0.4);
    /* creates a border around the scroll thumb */
}