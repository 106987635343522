/* Assuming this is included in ResearchCard.css or a separate ResearchCardToLearn.css */
.research-card-to-learn {

    /* Default background color */

    border: 2px solid transparent;
    /* Default border color set to transparent */
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    margin: 1rem 0;
    color: white;
    cursor: pointer;
    overflow: visible;

    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
}

.research-videoinfo {
    font-size: medium;

}