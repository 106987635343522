.menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 1rem;
}

.menu-buttons {
    display: flex;
    justify-content: center;
    flex-grow: 1;
    /* Allows it to take up available space, pushing the coins to the right */
}

.coin-display {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 0;
    color: gold;
    /* Ensures it does not expand and stays to the right */
}