.event-cards-container {
    display: flex;
    flex-wrap: wrap;
    /* Umožní, aby sa položky zalomili na nový riadok */
    justify-content: space-evenly;
    /* Zarovná položky na stred */
    gap: 20px;
    /* Vytvorí medzeru medzi kartami */
    padding: 20px;
    /* Pridá vnútorný odstup okolo obsahu kontajnera */
}

.event-card {
    background-color: rgba(255, 255, 255, 0.1);

    padding: 1rem;
    border-radius: 15px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
    margin: 1rem 0;
    color: white;
    width: 280px;
    z-index: 50 !important;
}

.event-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
    font-family: 'Roboto', sans-serif;
    font-size: 1.8rem;
    color: #ffffff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}




.event-card-timeDuration {
    text-align: right;
    /* Zarovnanie textu vpravo */
    font-size: small;
}

.event-date {
    font-weight: bold;
    /* Tučný text pre dátum */
}

.event-duration {
    display: flex;
    /* Flexbox pre horizontálne usporiadanie ikony a textu */
    align-items: center;
    text-align: right;
    justify-content: flex-end;
    /* Centruje položky vertikálne */

}

.timer-icon {
    width: 15px;
    /* Šírka ikony */
    height: 15px;
    /* Výška ikony */
    margin-left: 5px;
}

.person-icon {
    width: 15px;
    /* Šírka ikony */
    height: 15px;
    /* Výška ikony */
    margin-left: 5px;
}

.event-footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

}

.event-description {
    margin-bottom: 1rem;
    font-size: 1rem;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    /* Number of lines you want to display */
    overflow: hidden;
    text-overflow: ellipsis;
}

.event-description.expanded {
    -webkit-line-clamp: none;
    /* Removes the line clamp when expanded */
}

.event-name {
    font-size: 1.5rem;
    font-weight: bold;
}

.event-attribute-level {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    color: white;

    width: 30px;
    height: 30px;
    border-radius: 20%;


    justify-content: center;
    font-size: 1.2rem;
    flex-shrink: 0;
    position: relative;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/*
.event-attribute-icon {
    position: absolute;
    width: 30px;
    /* Adjust based on your preference 
    height: 30px;

    top: 12%;

    left: 12%;

    object-fit: contain;
   
}*/

.event-attribute-icon {
    width: 20px;
    height: 20px;
}

.event-marker-icon {

    width: 70%;
    /* Adjust size as needed */
    height: auto;

}