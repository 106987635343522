.containerVerificationRequests {
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 1vh;
    border-radius: 15px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
    width: 75vw;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-bottom: 10vh;
    color: white;
    min-height: 75vh;

}

.menu {
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.table th,
.table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.table th {
    background-color: #6E48AA;
    color: white;
}

.table tr:nth-child(even) {
    background-color: rgba(255, 255, 255, 0.1);
}

.table tr:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

.table button {
    background: rgba(255, 255, 255, 0.15);
    color: rgba(255, 255, 255, 0.85);
    border: none;
    border-radius: 10px;
    padding: 5px 10px;
    cursor: pointer;
    transition: background 0.3s, color 0.3s;
}

.table button:hover {
    background: rgba(255, 255, 255, 0.25);
    color: #FFD700;
}


/* Button styles applied globally to both table and standalone buttons */
.button {
    background: rgba(255, 255, 255, 0.15);
    color: rgba(255, 255, 255, 0.85);
    border: none;
    border-radius: 10px;
    padding: 5px 10px;
    cursor: pointer;
    transition: background 0.3s, color 0.3s;
}

.button:hover {
    background: rgba(255, 255, 255, 0.25);
    color: #FFD700;
}

/* Specific styles for the fetch button if needed, otherwise, it inherits the above */
.fetch-button {
    margin-top: 20px;
    /* Adds space above the button */
    width: auto;
    /* Adjust width as necessary, or keep it auto */
}

@media only screen and (max-width: 375px) {
    .verification-modal-overlay {
        height: 100vh;
        overflow: auto;
    }

    .verification-modal-content {
        top: 50%;
        transform: translateY(-50%);
        max-height: 90vh;
    }
}