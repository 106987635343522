.bottom-navbar {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 6vh;
    /* Dynamic height based on the viewport height */
    background: linear-gradient(135deg, #9D50BB 0%, #6E48AA 100%);
    color: white;
    padding: 0.5vh 0;
    /* Small vertical padding */
    box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.3);
    font-family: 'Ubuntu Condensed', sans-serif;
    z-index: 1000;
    /* Ensure it stays on top of other content */
}

.bottom-navbar button {
    background: none;
    border: none;
    color: rgba(255, 255, 255, 0.85);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 0.3s ease;
}

.bottom-navbar .active {
    color: #FFD700;
    /* Highlight the active icon */
}



.fa-iconB {
    font-size: 5vh;
    /* Or any other size */
}